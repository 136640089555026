<template>
  <div class='card'>
    <div class='card-body'>
      <!-- search input -->
      <div class='custom-search d-flex justify-content-end'>
        <!-- <div class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="$router.push({name: 'user-page', params: {id: 'new'}})"
          >
            Новый пользователь
          </b-button>
        </div> -->
        <b-form-group>
          <div class='d-flex align-items-center'>
            <label class='mr-1'>Поиск</label>
            <b-form-input
              v-model='searchTerm'
              placeholder='Search'
              type='text'
              class='d-inline-block'
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        :columns='columns'
        :rows='rows'
        :search-options='{
          enabled: true,
          externalQuery: searchTerm
        }'
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot='table-row'
          slot-scope='props'
        >

          <span v-if="props.column.field === 'operation'">
              {{ props.row.transaction.operation }}
          </span>

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant='statusVariant(props.row.transaction.status)'>
              {{ props.row.transaction.status }}
            </b-badge>
          </span>

          <span v-if="props.column.field === 'type'">
              {{ props.row.type }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant='link'
                toggle-class='text-decoration-none'
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon='MoreVerticalIcon'
                    size='16'
                    class='text-body align-middle mr-25'
                  />
                </template>
                <router-link
                  :to="{name: 'transaction', params:{ id: props.row.transaction.id}}"
                  class='edit-button'
                >
                  <feather-icon
                    icon='Edit2Icon'
                    class='mr-50'
                  />
                  <span>Просмотр</span>
                </router-link>

                <!-- <b-dropdown-item
                  v-b-modal.modal-primary
                  variant="outline-primary"
                  @click="userToDelete=props.row.id"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Удалить</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <infinite-loading
        spinner='bubbles'
        @infinite='infiniteScroll'
      >
        <div slot='no-more'></div>
        <div slot='no-results'></div>
      </infinite-loading>
    </div>

    <div>
      <div class='demo-inline-spacing' />
      <b-modal
        id='modal-primary'
        v-model='modalIsOpen'
        ok-only
        ok-title='Отмена'
        modal-class='modal-primary'
        centered
        title='Primary Modal'
      >
        <div>Вы действительно хотите удалить пользователя?</div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class='mt-1'
          variant='primary'
          @click='deleteItem(userToDelete)'
        >
          Удалить
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import InfiniteLoading from 'vue-infinite-loading'
import crud_module from '../../libs/crud_module'

const transactionsModule = crud_module({
  entity_name: 'sberbank-transactions'
})

export default {
  components: {
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BDropdown,
    // BDropdownItem,
    BButton,
    InfiniteLoading
  },
  directives: {
    Ripple
  },
  data() {
    return {
      skipCount: 9,
      maxResultCount: 9,
      modalIsOpen: false,
      userToDelete: null,
      columns: [
        {
          label: 'Операция',
          field: 'operation'
        },
        {
          label: 'Статус',
          field: 'status'
        },
        {
          label: 'Тип',
          field: 'type'
        },
        {
          label: 'Дата',
          field: this.formatDate
        },
        {
          label: 'Действие',
          field: 'action'
        }
      ],
      rows: [],
      pageLength: 15,
      dir: false,
      searchTerm: '',
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied'
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info'
        }
      ]
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info'
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    }
  },
  async created() {
    try {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount
      }
      const response = await transactionsModule.fetchItems(payload)
      this.rows = response.data.rows
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async infiniteScroll($state) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount
      }
      await transactionsModule.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount
      }
      await transactionsModule.deleteItem(id)
      await transactionsModule.fetchItems(payload).then(response => {
        this.rows = response.data.rows
      })
      this.modalIsOpen = false
    },
    formatDate(rowObj) {
      const newDate = new Date(rowObj.transaction.createdAt)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const newStr = newDate.toLocaleDateString('ru', options)
      return newStr
    }
  }
}
</script>

<style lang='scss' scoped>

</style>

<style lang='scss'>
.edit-button {
  padding: 0.65rem 1.28rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(115, 103, 240, 0.12);
  }
}

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
